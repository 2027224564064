<template>
    <div>
        <div v-if="!isSentEmail">
            <div
                class="form-msg-wrapper"
                :class="[isFormMsg ? 'show' : '', isError ? 'error' : 'success']"
            >
                <p>{{ formErrorContent }}</p>
            </div>
            <p class="description">{{ $t('signup.verify.description') }}</p>
            <form @submit.prevent="sendEmail()">
                <div class="field">
                    <input
                        id="email"
                        type="text"
                        class="inputfield w-full"
                        :class="[errors.EMAIL.mandatory ? 'error' : '']"
                        :placeholder="$t('miscellaneous.placeholders.verify_email')"
                        v-model="email"
                        @change="validateEmail"
                    />
                </div>
                <div class="field login-btn">
                    <ButtonPV
                        :label="$t('signup.verify.proceed')"
                        class="button-raised w-full primary"
                        type="submit"
                        :disabled="isDisabled"
                    />
                </div>
            </form>
        </div>

        <div v-if="isSentEmail" class="verified-message">
            <i class="pi pi-check-circle success-icon"></i>
            <p class="message">{{ $t('signup.verify.success_message') }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, ref } from 'vue'
import utils from '@/end-user-portal/shared/mixins/utils'
import commonUtils from '@/shared/mixins/utils'
import router from '@/router'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'

export default defineComponent({
    setup() {
        const isDisabled = ref(true)
        const isSentEmail = ref(false)
        const isError = ref(false)
        const isFormMsg = ref(false)
        const formErrorContent = ref('')
        const email = ref('')
        const errors: Ref<ErrorObjectDTO> = ref({
            EMAIL: { mandatory: false, invalidValue: false },
        })

        const validateEmail = () => {
            errors.value.EMAIL.mandatory = utils.validateFields(email.value, 'string')
            errors.value.EMAIL.invalidValue = utils.validateFields(email.value, 'email')
            isDisabled.value = utils.hasErrors(errors.value)
        }

        const sendEmail = async () => {
            const data = {
                email: email.value.toLowerCase(),
                partner: commonUtils.subdomain,
                verify_email_url: `${window.location.origin}/portal/auth/signup`,
            }

            AuthenticationService.sendEmail(data)
                .then(() => {
                    isSentEmail.value = true
                })
                .catch(async (error: { response: { data: { detail: string } } }) => {
                    isError.value = true
                    isFormMsg.value = true
                    formErrorContent.value = error.response.data.detail
                    await commonUtils.sleep(3000)
                    router.push({ name: 'LoginView' })
                })
        }

        return {
            email,
            errors,
            isFormMsg,
            formErrorContent,
            isError,
            isDisabled,
            sendEmail,
            validateEmail,
            isSentEmail,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
@import '@/end-user-portal/assets/styles/login';
.verified-message {
    text-align: center;
    .success-icon {
        color: $green;
        font-size: 2rem;
    }
    .message {
        font-size: 1rem;
    }
}
</style>
