<template>
    <div class="login-wrapper">
        <div v-if="logo" class="logo-wrapper">
            <img :src="`${logo}`" id="logo" />
        </div>
        <div class="form-wrapper">
            <div
                :class="[
                    'right-block',
                    $router.currentRoute.value.name === 'LoginView' ? 'login' : '',
                ]"
            >
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import store from '@/store'

export default defineComponent({
    setup() {
        const logo = computed(() => store.state.config.logo)
        return {
            logo,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
@import '@/end-user-portal/assets/styles/login';
</style>
