import axiosInstance from '@/shared/axios/AxiosInstance'
import Variables from '@/end-user-portal/shared/constant/Variables'
import utils from '@/shared/mixins/utils'

const defaultHeaders = {
    headers: {
        'X-CLIENT-DOMAIN': 'end_user_portal',
        'X-SUBDOMAIN': utils.subdomain,
    },
}

export default {
    getQAList(paginationStart: number, limit: number, status?: string, period?: string) {
        return axiosInstance.get(Variables.API.qaList, {
            ...defaultHeaders,
            params: { page: paginationStart, page_size: limit, legislation_year: period, status },
        })
    },

    getFilterOptions() {
        return axiosInstance.get(Variables.API.getFilterOptions)
    },
    resubmitRegistration(id: number, data: { period: number }) {
        return axiosInstance.put(`${Variables.API.qaList}${id}/duplicate_for_new_period/`, data, {
            ...defaultHeaders,
        })
    },
}
