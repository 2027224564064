import variables from '@/client-app/shared/variables'
import { createStore } from 'vuex'
import { Profile } from '@/end-user-portal/dto/profile'
import Variables from '@/end-user-portal/shared/constant/Variables'
import commonVariables from '@/shared/Variables'

const storedQid = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.QUOTE_ID)
const storedQUUID = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.QUOTE_UUID)
const storedBevRegId = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.BEVREG_ID)
const storedResubmitPopupDetailsEUP = localStorage.getItem(
    variables.LOCAL_STORAGE_ITEMS.RESUBMIT_TNC
)
// set initial values as already stored values on refresh page
// const localStorageUser = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.USER)
const storedTcAndPp = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.TC_AND_PP)
const tcAndPp = storedTcAndPp && JSON.parse(storedTcAndPp)
const storedEnercity = localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.ENERCITY)
const enercity = storedEnercity && JSON.parse(storedEnercity)
const storedEmail = localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.EMAIL)
const email = storedEmail && JSON.parse(storedEmail)
const partner = window.location.host.split(':')[0].split('.')[0]
const quoteId = storedQid ? storedQid : ''
const quoteUUID = storedQUUID ? storedQUUID : ''
const bevRegistrationId = storedBevRegId ? storedBevRegId : ''
const resubmitPopupDetailsEUP =
    storedResubmitPopupDetailsEUP && JSON.parse(storedResubmitPopupDetailsEUP)

export default createStore({
    state: {
        // id: uid ? uid : null,
        // contract: contract ? contract :"",
        // email: email ? email : "",
        // unverifiedEmail : unverifiedEmail? unverifiedEmail : "",
        // user: user? user : {id: null},
        // firstName: firstname ? firstname : "",
        // lastName: lastname ? lastname : "",
        // streetName: streetname ? streetname : "",
        // streetNumber: streetnumber ? streetnumber : "",
        // postalCode: postalcode ? postalcode: "",
        // city: city ? city: "",
        // partnerVariable: partnervariable ? partnervariable: "",
        // accountName: accountname ? accountname: "",
        // IBAN: iban ? iban : "",
        partner,
        bevRegistrationId,
        resubmitPopupDetailsEUP,
        // Config
        config: {
            pages: {
                welcome: {
                    title: '',
                    register: '',
                    four_steps: '',
                    step1: '',
                    step2: '',
                    step3: '',
                    step4: '',
                    by_line: '',
                },
                faq: [
                    {
                        question: '',
                        answer: '',
                    },
                    {
                        question: '',
                        answer: '',
                    },
                ],
                login: {
                    intro: '',
                    contract_number: '',
                    invalid_login: '',
                    display_partner_variable: false,
                },
                terms: {
                    agreed: '',
                    display_checkbox_free_text_permission: true,
                    free_text_permission_text: '',
                    make_free_text_mandatory: false,
                    tnc_title: '',
                    show_email_promotions_flag: false,
                    email_promotions_text: '',
                    fly_out_text: null,
                },
                termsdetails: {
                    text: '',
                    file: null,
                },
                privacy: {
                    agreed: '',
                },
                privacydetails: {
                    text: '',
                    file: null,
                },
                finish: {
                    text: '',
                    title: '',
                    countdown_seconds: 0,
                    is_auto_redirect_enabled: false,
                    is_button_redirect_enabled: false,
                    redirect_url: '',
                },
                payment: {
                    payment_option: '',
                    title: '',
                    text_details: '',
                    input_field_text: '',
                    chargecard_placeholder_text: '',
                    client_detail_label: '',
                    text_for_bank: '',
                    text_for_charge_card: '',
                    text_for_bank_or_charge_card: '',
                    text_for_bank_and_optional_charge_card: '',
                    text_for_none_of_the_above: '',
                    payment_option_placeholder_text: '',
                    firstname_placeholder_text: '',
                    lastname_placeholder_text: '',
                },
            },
            colors: {
                tqs_alert_text_color: '',
                tqs_background_gradient_left: '',
                tqs_background_gradient_right: '',
                tqs_button_color_left: '',
                tqs_button_color_right: '',
                tqs_circle_color: '',
                tqs_circle_inner_text: '',
                tqs_desktop_box_colour_left: '',
                tqs_desktop_box_colour_right: '',
                tqs_desktop_button_colour_left: '',
                tqs_desktop_button_colour_right: '',
                tqs_frontend_text_color: '',
                tqs_input_border_color: '',
                tqs_input_enabled_border_color: '',
                tqs_text_color: '',
            },
            page_title: '',
            detail: '',
            favicon: '',
            logo: '',
            by_thg_quoten_gmbh: false,
            thg_quoten_is_contractual_partner: true,
            font_family: 'Open Sans',
            item_alignment: 'center',
            display_title_dot: false,
            partner: '',
            authentication_flow: 'link',
            faq_youtube_video_link: '',
            gtm_id: '',
            enable_private_business_registration: false,
            show_gender_option: false,
            gender_option: 1,
            show_impressum: false,
            frontend_active: false,
            impressum_details: '',
            show_data_privacy: false,
            end_user_portal_enable: false,
            show_client_fee_euro_amount: false,
            data_privacy_text: '',
            qa_create_choices: {
                current_year: false,
                next_year: false,
                current_year_option_title: '',
                current_year_option_subtext: '',
                next_year_option_title: '',
                next_year_option_subtext: '',
                no_allowed_registration_years_message: '',
            },
            partneradvertisement: {
                type: '',
                freetext: '',
                image: '',
                url: '',
                enabled: false,
            },
        },

        tcAndPp,

        // Is Enercity the partner?
        enercity: enercity ? enercity : false,

        // Only keeping a reference to email when we need it for the OTP view.
        email,

        quoteId,
        quoteUUID,
        toaster: {
            showToaster: false,
            isSuccess: false,
            toasterContent: '',
        },
        emailVerificationStatus: '',
        profileDetails: {
            email: '',
            first_name: '',
            last_name: '',
            username: '',
        },
        menuIsOpened: false,
        emailVerificationDetails: {
            emailVerificationCompleted: false,
            emailVerified: true,
            email: email,
            is_unknown_user: false,
        },
    },

    mutations: {
        setConfig: (state, config) => {
            state.config = config
        },

        setTcAndPP: (state, tcAndPp) => {
            state.tcAndPp = tcAndPp
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.TC_AND_PP, JSON.stringify(tcAndPp))
        },

        setEnercity: (state, enercity: boolean) => {
            state.enercity = enercity
        },

        setEmail: (state, email) => {
            state.email = email
        },

        setQuoteId: (state, quoteId) => {
            state.quoteId = quoteId
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.QUOTE_ID, quoteId)
        },

        setQuoteUUID: (state, quoteUUID) => {
            state.quoteUUID = quoteUUID
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.QUOTE_UUID, quoteUUID)
        },

        setBevRegistrationId: (state, bevId) => {
            state.bevRegistrationId = bevId
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.BEVREG_ID, bevId)
        },
        SET_TOASTER_VALUES(state, data) {
            state.toaster = data
            setTimeout(() => {
                state.toaster = {
                    showToaster: false,
                    isSuccess: false,
                    toasterContent: '',
                }
            }, 4000)
        },
        EMAIL_VERIFICATION_STATUS(state, data) {
            state.emailVerificationStatus = data
        },
        SET_PROFILE_DETAILS(state, data: Profile) {
            state.profileDetails = data
            localStorage.setItem(Variables.localStorageItems.profileDetails, JSON.stringify(data))
        },
        SET_MENU_IS_OPEN(state, data: boolean) {
            state.menuIsOpened = data
        },
        setEmailVerificationDetails(state, data) {
            state.emailVerificationDetails = data
        },
        setResubmitPopupDetailsEUP(state, data) {
            state.resubmitPopupDetailsEUP = data
            localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.RESUBMIT_TNC, JSON.stringify(data))
        },
    },

    actions: {},

    modules: {},
})
