import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4920412"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "terms view-root" }
const _hoisted_2 = {
  key: 0,
  class: "fas fa-circle mr-2"
}
const _hoisted_3 = { class: "action-wrapper mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TermsList = _resolveComponent("TermsList")!
  const _component_ButtonPV = _resolveComponent("ButtonPV")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.utils.getTitleClass())
    }, [
      (_ctx.showDot)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : _createCommentVNode("", true),
      _createTextVNode(" " + _toDisplayString(_ctx.conf.tnc_title), 1)
    ], 2),
    _createVNode(_component_TermsList, { ref: "termsListRef" }, null, 512),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_ButtonPV, {
        label: "Weiter",
        class: "primary w-full",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.submit())),
        id: "terms-button",
        disabled: _ctx.displayNoYearAvailableMessage
      }, null, 8, ["disabled"])
    ])
  ]))
}