// API URLs values
const endUserPortalSlug = 'end_user_portal'
const API = {
    signupURL: `/${endUserPortalSlug}/create_account/`,
    loginURL: '/auth/user/login/',
    logoutURL: '/auth/user/logout/',
    qaList: `/${endUserPortalSlug}/quota_applications/`,
    getNewAccessTokenURL: '/auth/user/token/refresh/',
    getProfileURL: `/${endUserPortalSlug}/end_user_profile/`,
    resetPasswordVerifyEmailURL: '/auth/user/password-reset/verify-email/',
    resetPasswordVerifyURL: '/auth/user/password-reset/verify',
    resetPasswordCompleteURL: '/auth/user/password-reset/complete/',
    getFrontendConfigDetailsURL: '/configs',
    getFilterOptions: `/${endUserPortalSlug}/quota_applications/status_and_period_for_filters/`,
    verifySignupEmail: `/${endUserPortalSlug}/verify_token/`,
    sendEmail: `/${endUserPortalSlug}/email_register/`,
    verifyRecaptchaOTP: `/auth/2fa/otp/verify/`,
    resendRecaptchaOtpCode: `/auth/2fa/otp/resend/`,
}

// localstorage variable values
const localStorageItems = {
    accessToken: 'token',
    refreshToken: 'refresh',
    QADetails: 'qa-details',
    subPartnerDetails: 'sub-partner-details',
    profileDetails: 'profile-details',
    frontendConfig: 'frontend-config',
    filterValues: 'filter-values',
    settlementBatchId: 'settlement-batch-id',
    singleDealDetails: 'single-deal-details',
    selectedSubPartner: 'subpartner-id',
}

// part size which you want to split the file
const partSize = 100
const postFixUrl = 'thg-quoten.de'

// vue-editor toolbar options(customize)
const toolBarOptionsEditor = [
    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    ['link', 'image', 'video'],
]

export default {
    API,
    localStorageItems,
    partSize,
    postFixUrl,
    toolBarOptionsEditor,
}
