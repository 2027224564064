<template>
    <div class="billing view-root">
        <div :class="['mb-3', utils.getTitleClass()]">
            <i v-if="showDot" class="fas fa-circle mr-2"></i>
            <span v-if="editable">Geben Sie Ihre persönlichen Daten ein</span>
            <span v-if="!editable">Überprüfen Sie Ihre Angaben</span>
        </div>
        <p :style="`text-align: ${alignment}`" v-if="editable">
            {{
                payment.client_detail_label !== '' && payment.client_detail_label !== null
                    ? payment.client_detail_label
                    : 'Bitte geben Sie hier Ihre Kontaktdaten gemäß dem hochgeladenen Fahrzeugschein ein.'
            }}
        </p>
        <p :style="`text-align: ${alignment}`" v-if="!editable">
            <b>Persönliche Daten:</b>
        </p>
        <h3 v-if="showPrivateOrCompany">Fahrzeughalter ist</h3>
        <div v-if="showPrivateOrCompany" class="client-type-wrapper">
            <div class="flex align-items-center mb-2 radiobuttons">
                <RadioPV
                    v-model="privateOrCompany"
                    inputId="private"
                    name="private"
                    value="private"
                    :disabled="!editable"
                    @change="switchPrivatCompany()"
                />
                <label for="private" class="ml-2">Privat</label>
            </div>
            <div class="flex align-items-center radiobuttons mb-2">
                <RadioPV
                    v-model="privateOrCompany"
                    inputId="business"
                    name="business"
                    value="business"
                    :disabled="!editable"
                    @change="switchPrivatCompany()"
                />
                <label for="business" class="ml-2">Geschäftlich</label>
            </div>
        </div>
        <div v-if="isCompany && showPrivateOrCompany">
            <h3>Sind Sie umsatzsteuerpflichtig?</h3>
            <div>
                <div class="flex align-items-center mb-2">
                    <RadioPV
                        v-model="FORM.VAT_MANDATORY"
                        inputId="yes-vat"
                        name="yes-vat"
                        value="yes-vat"
                        :disabled="!editable"
                    />
                    <label for="yes-vat" class="ml-2">Ja</label>
                </div>
                <div class="flex align-items-center mb-2">
                    <RadioPV
                        v-model="FORM.VAT_MANDATORY"
                        inputId="no-vat"
                        name="no-vat"
                        value="no-vat"
                        :disabled="!editable"
                        @change="noVatSelected"
                    />
                    <label for="no-vat" class="ml-2">Nein</label>
                </div>
            </div>
            <small class="p-error" v-if="errors.VAT_BUTTON_SELECTION.missing"
                >Auswahl treffen.</small
            >
        </div>
        <!--
        <p v-if="isVAT">
            Vat specific component if needed
        </p>
        -->
        <br />
        <form @submit.prevent="summaryView">
            <fieldset :disabled="!editable">
                <div class="p-fluid">
                    <div v-if="isCompany && showPrivateOrCompany">
                        <div class="field">
                            <span class="p-float-label">
                                <InputText id="username" type="text" v-model="FORM.COMPANY_NAME" />
                                <label for="username">Unternehmen</label>
                            </span>
                            <small class="p-error" v-if="errors.COMPANY_NAME.missing"
                                >Eingabe erforderlich.</small
                            >
                        </div>
                        <div class="field" v-if="FORM.VAT_MANDATORY === 'yes-vat'">
                            <span class="p-float-label p-input-icon-right">
                                <i
                                    class="pi pi-spin pi-spinner"
                                    v-if="displayVatValidationSpinner"
                                />

                                <i class="pi pi-check-circle" v-if="displayVatChecked" />
                                <InputText
                                    id="vatNumber"
                                    type="text"
                                    v-model.trim="FORM.TAX_NUMBER"
                                    @blur="vatValidation()"
                                />
                                <label for="vatNumber"> Ust.-ID (DE…) oder St.Nr</label>
                            </span>
                            <small class="p-error" v-if="errors.TAX_NUMBER.invalid">
                                Bitte überprüfen Sie das Format: Ust.-ID (DE…) mit 9 Ziffern, oder
                                ihre Steuernummer.
                            </small>
                            <small
                                class="p-error"
                                v-if="
                                    isVatValidationResponseRecieved && displayVatBEValidationError
                                "
                                >{{ vatErrorMessage }}</small
                            >
                            <small class="p-error" v-if="errors.TAX_NUMBER.missing"
                                >Eingabe erforderlich.</small
                            >
                        </div>
                    </div>
                    <div class="field group-fields">
                        <div class="gender-dropdown mr-2" v-if="isGenderOptionsEnabled">
                            <DropdownPV
                                v-model="FORM.GENDER"
                                :options="genders"
                                optionLabel="name"
                                placeholder="Anrede"
                                :disabled="!editable"
                            />
                        </div>
                        <div class="firstname-input w-full">
                            <span class="p-float-label">
                                <InputText id="firstname" type="text" v-model="FORM.FIRST_NAME" />
                                <label for="firstname">{{
                                    payment.firstname_placeholder_text !== '' &&
                                    payment.firstname_placeholder_text !== null
                                        ? payment.firstname_placeholder_text
                                        : 'Vorname'
                                }}</label>
                            </span>
                            <small class="p-error" v-if="errors.FIRST_NAME.missing"
                                >Eingabe erforderlich.</small
                            >
                        </div>
                    </div>
                    <div class="field">
                        <span class="p-float-label">
                            <InputText id="lastname" type="text" v-model="FORM.LAST_NAME" />
                            <label for="lastname">{{
                                payment.firstname_placeholder_text !== '' &&
                                payment.firstname_placeholder_text !== null
                                    ? payment.lastname_placeholder_text
                                    : 'Nachname'
                            }}</label>
                        </span>
                        <small class="p-error" v-if="errors.LAST_NAME.missing"
                            >Eingabe erforderlich.</small
                        >
                    </div>
                    <div class="field">
                        <span class="p-float-label">
                            <InputText id="streetname" type="text" v-model="FORM.STREET_NAME" />
                            <label for="streetname">Straße</label>
                        </span>
                        <small class="p-error" v-if="errors.STREET_NAME.missing"
                            >Eingabe erforderlich.</small
                        >
                        <small class="p-error" v-if="errors.STREET_NAME.invalid"
                            >Straße name ungultig</small
                        >
                    </div>
                    <div class="field">
                        <span class="p-float-label">
                            <InputText id="streetnumber" type="text" v-model="FORM.STREET_NUMBER" />
                            <label for="streetnumber">Hausnummer</label>
                        </span>
                        <small class="p-error" v-if="errors.STREET_NUMBER.missing"
                            >Eingabe erforderlich.</small
                        >
                    </div>
                    <div class="field">
                        <span class="p-float-label">
                            <InputText id="postalcode" type="text" v-model="FORM.POSTAL_CODE" />
                            <label for="postalcode">Postleitzahl</label>
                        </span>
                        <small class="p-error" v-if="errors.POSTAL_CODE.missing"
                            >Eingabe erforderlich.</small
                        >
                    </div>
                    <div class="field">
                        <span class="p-float-label">
                            <InputText id="city" type="text" v-model="FORM.CITY" />
                            <label for="city">Ort</label>
                        </span>
                        <small class="p-error" v-if="errors.CITY.missing"
                            >Eingabe erforderlich.</small
                        >
                    </div>
                    <div v-if="payment.payment_option !== 'NONE'">
                        <p v-if="editable">
                            <b>{{ payment.title }}</b>
                        </p>
                        <p v-if="editable">
                            {{ payment.text_details }}
                        </p>
                    </div>
                    <div
                        class="both-a-and-b"
                        v-if="payment.payment_option === 'BANKORCHARGECARD' && editable"
                    >
                        <div class="field">
                            <DropdownPV
                                v-model="FORM.PAYMENT_METHOD"
                                :options="paymentMethodArr"
                                optionLabel="name"
                                :placeholder="payment.payment_option_placeholder_text"
                                @change="paymentMethodOnChange($event)"
                                :disabled="!editable"
                            />
                            <small class="p-error" v-if="isPaymentMethodSelected"
                                >Eingabe erforderlich.</small
                            >
                        </div>
                    </div>
                    <div
                        class="bank-details"
                        v-if="
                            payment.payment_option === 'BANK' ||
                            isIBAN ||
                            payment.payment_option === 'BANKANDOPTIONALCHARGECARD'
                        "
                    >
                        <div class="field">
                            <span class="p-float-label">
                                <InputText
                                    id="billing-name"
                                    type="text"
                                    v-model.trim="FORM.ACCOUNT_NAME"
                                />
                                <label for="lastname">Name von Kontoinhaber</label>
                            </span>
                            <small class="p-error" v-if="errors.ACCOUNT_NAME.missing"
                                >Eingabe erforderlich.</small
                            >
                            <small class="p-error" v-if="errors.ACCOUNT_NAME.validTextLength"
                                >Name von kontoinhaber ungultig</small
                            >
                            <small class="p-error" v-if="errors.ACCOUNT_NAME.invalidCharacters">
                                Bitte geben Sie Ihren Namen ohne Sonderzeichen und ohne Umlaute ein.
                            </small>
                        </div>
                        <div class="field">
                            <span class="p-float-label">
                                <InputText
                                    id="iban"
                                    type="text"
                                    v-model="FORM.IBAN"
                                    @input="removeIBANspaces"
                                />
                                <label for="contract">IBAN</label>
                            </span>
                            <small class="p-error" v-if="errors.IBAN.missing"
                                >Eingabe erforderlich.</small
                            >
                            <small class="p-error" v-if="errors.IBAN.invalid"
                                >IBAN ist nicht gültig</small
                            >
                            <small class="p-error" v-if="emojis"
                                >Bitte keine Emojis verwenden</small
                            >
                        </div>
                    </div>
                    <div
                        class="charge-card-id"
                        v-if="
                            payment.payment_option === 'CHARGECARD' ||
                            isChargeCard ||
                            payment.payment_option === 'BANKANDOPTIONALCHARGECARD'
                        "
                    >
                        <div class="field">
                            <p class="input-label" v-if="editable">
                                {{ payment.input_field_text }}
                            </p>
                            <span class="p-float-label">
                                <InputText
                                    id="charge-card-id"
                                    type="text"
                                    v-model="FORM.CHARGE_CARD_ID"
                                />
                                <label for="lastname">{{
                                    payment.chargecard_placeholder_text
                                }}</label>
                            </span>
                            <small class="p-error" v-if="errors.CHARGE_CARD_ID.missing"
                                >Eingabe erforderlich.</small
                            >
                        </div>
                    </div>
                </div>

                <div class="action-wrapper billing">
                    <div class="mt-3 mb-3">
                        <MessagePV severity="info" :closable="false">
                            Mit Anklicken des Button „Verbindlich abschließen“ bestätige ich, dass
                            ich der Halter dieses Fahrzeugs bin oder vom Halter des Fahrzeugs zur
                            Beantragung der THG-Prämie ordnungsgemäß bevollmächtigt bin.
                        </MessagePV>
                        <div class="p-error" v-if="error">
                            <small> {{ errorMessage }}</small>
                        </div>
                    </div>
                    <ButtonPV
                        v-if="editable"
                        label="Verbindlich abschließen"
                        type="submit"
                        class="primary w-full"
                        id="view-summary"
                    />
                </div>
            </fieldset>
        </form>
        <div class="action-wrapper" v-if="!editable">
            <ButtonPV
                label="Verbindlich abschließen"
                class="primary w-full mb-2"
                @click="submit"
                id="submit-client"
            />
            <ButtonPV
                :label="editable ? 'Bearbeiten' : 'Korrigieren'"
                @click="editable = true"
                class="secondary w-full"
            />
        </div>

        <VueLoading
            :opacity="0.8"
            :active="isRedirecting"
            :can-cancel="true"
            :is-full-page="true"
            color="#778da0"
        >
            <div class="after-icon text-center">
                <h3>Bitte warten Sie kurz!</h3>
                <p>Sie werden zu der Registrierungsseite weitergeleitet...</p>
            </div>
        </VueLoading>

        <!-- loading spinner -->
        <VueLoading
            :opacity="0.8"
            :active="loading"
            :can-cancel="true"
            :is-full-page="true"
            color="#778da0"
        />
    </div>
</template>

<script lang="ts">
import router from '@/router'
import utils from '@/client-app/shared/mixins/utils'
import store from '@/store'
import variables from '@/client-app/shared/variables'
import { Ref, computed, defineComponent, ref } from 'vue'
import commonVariables from '@/shared/Variables'
import commonUtils from '@/shared/mixins/utils'
import APIService from '@/client-app/shared/APIService'
import IBAN from 'iban'
import { ErrorObjectDTO } from '@/shared/dto/CommonDTOs'
import { DropdownChangeEvent } from 'primevue/dropdown'

export default defineComponent({
    // if bev registration is completed when tring to navigate back to Validation, stay in the same page
    beforeRouteLeave(to, from, next) {
        if (
            to.name == 'Validation' &&
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.BEV_REGISTRATION_COMPLETED) ===
                'true'
        ) {
            next({ name: 'Billing', query: router.currentRoute.value?.query })
        } else {
            next()
        }
    },
    // let the navigation happen, only when the bev registration is completed
    beforeRouteEnter(to, from, next) {
        if (
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.BEV_REGISTRATION_COMPLETED) ===
            'true'
        ) {
            next()
        } else {
            if (window.location.href.includes('/b2b')) {
                next({
                    name: 'ValidationB2B',
                    query: router.currentRoute.value?.query,
                })
            } else {
                next({
                    name: 'Validation',
                    query: router.currentRoute.value?.query,
                })
            }
        }
    },
    setup() {
        const isRedirecting = ref(false)
        const showPrivateOrCompany = ref(store.state.config.enable_private_business_registration)
        const privateOrCompany = ref('private')
        const error = ref(false)
        const errorMessage = ref('')
        const vatErrorMessage = ref('')
        const chargeCardId =
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.CHARGE_CARD_ID) ||
            JSON.stringify(router.currentRoute.value.query.user_reference) ||
            ''
        const campaign =
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.CAMPAIGN) ||
            JSON.stringify(router.currentRoute.value.query.campaign) ||
            ''
        let chargeCardIdFieldValue
        // TODO: (#192) remove campaign value autofilling charge card id
        if (chargeCardId !== '') {
            chargeCardIdFieldValue = chargeCardId ? JSON.parse(chargeCardId) : ''
        } else {
            chargeCardIdFieldValue = ''
        }
        const FORM = ref({
            GENDER: { name: '', code: '' },
            FIRST_NAME: '',
            LAST_NAME: '',
            ACCOUNT_NAME: '',
            IBAN: '',
            CHARGE_CARD_ID: chargeCardIdFieldValue,
            PARTNER_VARIABLE: '',
            STREET_NAME: '',
            STREET_NUMBER: '',
            POSTAL_CODE: '',
            CITY: '',
            PAYMENT_METHOD: '',
            COMPANY_NAME: '',
            TAX_NUMBER: '',
            VAT_MANDATORY: '',
        })
        const loading = ref(false)
        const errors: Ref<ErrorObjectDTO> = ref({
            GENDER: { missing: false },
            FIRST_NAME: { missing: false },
            LAST_NAME: { missing: false },
            ACCOUNT_NAME: { missing: false, invalidCharacters: false, validTextLength: false },
            IBAN: { missing: false, invalid: false },
            CHARGE_CARD_ID: { missing: false },
            STREET_NAME: { missing: false, invalid: false },
            STREET_NUMBER: { missing: false },
            POSTAL_CODE: { missing: false },
            CITY: { missing: false },
            COMPANY_NAME: { missing: false },
            TAX_NUMBER: { missing: false, invalid: false },
            VAT_BUTTON_SELECTION: { missing: false },
        })
        const displayVatValidationSpinner = ref(false)
        const isVatValidationResponseRecieved = ref(false)
        const displayVatBEValidationError = ref(false)
        const displayVatChecked = ref(false)
        const editable = ref(true)
        const isIBAN = ref(false)
        const isChargeCard = ref(false)
        const emojis = ref(false)
        const isPaymentMethodSelected = ref(false)
        const paymentDetails = ref(store.state.config.pages.payment)
        const isGenderOptionsEnabled = ref(store.state.config.show_gender_option)
        const genders = ref([
            { name: 'Frau', code: 'woman' },
            { name: 'Herr', code: 'man' },
        ])
        const serverErrors = ref([])
        if (store.state.config.gender_option === 2) {
            genders.value.push({ name: 'Divers', code: 'diverse' })
        } else if (store.state.config.gender_option === 3) {
            genders.value.push(
                { name: 'Divers', code: 'diverse' },
                { name: 'Möchte ich nicht sagen', code: 'not_specified' }
            )
        }
        const dropDownBankLabel =
            paymentDetails.value.text_for_bank !== ''
                ? paymentDetails.value.text_for_bank
                : 'Bankverbindung'
        const dropDownChargeCardLabel =
            paymentDetails.value.text_for_charge_card !== ''
                ? paymentDetails.value.text_for_charge_card
                : 'Alternative Prämie'
        let selectedPaymentMethod: string
        let URLVariableValue =
            localStorage.getItem(variables.LOCAL_STORAGE_ITEMS.URL_VARIABLE_VALUE) ||
            JSON.stringify(router.currentRoute.value.fullPath)
        URLVariableValue = URLVariableValue.replace('/billing', '/')
        const freeTextChecked = localStorage.getItem(
            variables.LOCAL_STORAGE_ITEMS.FREE_TEXT_CHECKED
        )
        const isEndUserPortalEnabled = ref(store.state.config.end_user_portal_enable)
        let freeText = freeTextChecked && JSON.parse(freeTextChecked) === 'true' ? true : false
        // Don't know why it has to be parsed when it is parsed from the url in the email verification step,
        // but in the otp flow we can just compare it, so doing that too.
        if (freeTextChecked === 'true') {
            freeText = true
        }

        // IBAN name must not have special characters except / - ? : ( ) . , ‘ +
        const validateIBNname = (name: string) => {
            const regex = /^[-a-zA-Z0-9/?:().,+‘\s]+$/
            return regex.test(name)
        }

        const validateBusinessFields = () => {
            errors.value.VAT_BUTTON_SELECTION.missing = utils.validateMandatoryFields(
                FORM.value.VAT_MANDATORY,
                'string'
            )

            // If vat mandatory validate tax
            if (FORM.value.VAT_MANDATORY === 'yes-vat') {
                errors.value.TAX_NUMBER.missing = utils.validateMandatoryFields(
                    utils.removeSpace(FORM.value.TAX_NUMBER),
                    'string'
                )
                // Validate the Ust-ID
                if (FORM.value.TAX_NUMBER) {
                    errors.value.TAX_NUMBER.invalid = !utils.validateTaxID(
                        utils.removeSpace(FORM.value.TAX_NUMBER)
                    )
                }

                // TODO: make sure company name is not required always
                errors.value.COMPANY_NAME.missing = utils.validateMandatoryFields(
                    FORM.value.COMPANY_NAME,
                    'string'
                )
            } else {
                errors.value.COMPANY_NAME.missing = false
                errors.value.TAX_NUMBER.missing = false
            }
        }

        const validateForm = () => {
            // reset validation errors
            errors.value = {
                GENDER: { missing: false },
                FIRST_NAME: { missing: false },
                LAST_NAME: { missing: false },
                ACCOUNT_NAME: { missing: false, invalidCharacters: false, validTextLength: false },
                IBAN: { missing: false, invalid: false },
                CHARGE_CARD_ID: { missing: false },
                STREET_NAME: { missing: false, invalid: false },
                STREET_NUMBER: { missing: false },
                POSTAL_CODE: { missing: false },
                CITY: { missing: false },
                COMPANY_NAME: { missing: false },
                TAX_NUMBER: { missing: false, invalid: false },
                VAT_BUTTON_SELECTION: { missing: false },
            }

            errors.value.FIRST_NAME.missing = utils.validateMandatoryFields(
                FORM.value.FIRST_NAME,
                'string'
            )
            errors.value.LAST_NAME.missing = utils.validateMandatoryFields(
                FORM.value.LAST_NAME,
                'string'
            )
            errors.value.STREET_NAME.missing = utils.validateMandatoryFields(
                FORM.value.STREET_NAME,
                'string'
            )
            errors.value.STREET_NUMBER.missing = utils.validateMandatoryFields(
                FORM.value.STREET_NUMBER,
                'string'
            )
            errors.value.POSTAL_CODE.missing = utils.validateMandatoryFields(
                FORM.value.POSTAL_CODE,
                'string'
            )
            errors.value.CITY.missing = utils.validateMandatoryFields(FORM.value.CITY, 'string')

            // Validate the street name
            // (longer than 3 characters)
            if (FORM.value.STREET_NAME !== '') {
                errors.value.STREET_NAME.invalid = !utils.validateStringLength(
                    FORM.value.STREET_NAME,
                    3
                )
            }

            validatePaymentMethod()
            if (isCompany.value && showPrivateOrCompany.value) {
                validateBusinessFields()
            }
        }

        // payment method validation function
        const validatePaymentMethod = () => {
            if (FORM.value.ACCOUNT_NAME) {
                errors.value.ACCOUNT_NAME.invalidCharacters = !validateIBNname(
                    FORM.value.ACCOUNT_NAME
                )
                // longer than 2 characters
                errors.value.ACCOUNT_NAME.validTextLength = !utils.validateStringLength(
                    FORM.value.ACCOUNT_NAME,
                    2
                )
            }
            switch (paymentDetails.value.payment_option) {
                case 'BANK':
                    errors.value.ACCOUNT_NAME.missing = utils.validateMandatoryFields(
                        FORM.value.ACCOUNT_NAME,
                        'string'
                    )
                    errors.value.IBAN.missing = utils.validateMandatoryFields(
                        FORM.value.IBAN,
                        'string'
                    )
                    break
                case 'CHARGECARD':
                    errors.value.CHARGE_CARD_ID.missing = utils.validateMandatoryFields(
                        FORM.value.CHARGE_CARD_ID,
                        'string'
                    )
                    break
                case 'BANKORCHARGECARD':
                    if (selectedPaymentMethod === 'BANK') {
                        errors.value.ACCOUNT_NAME.missing = utils.validateMandatoryFields(
                            FORM.value.ACCOUNT_NAME,
                            'string'
                        )
                        errors.value.IBAN.missing = utils.validateMandatoryFields(
                            FORM.value.IBAN,
                            'string'
                        )
                        errors.value.CHARGE_CARD_ID.missing = utils.validateMandatoryFields(
                            FORM.value.CHARGE_CARD_ID,
                            ''
                        )
                    } else {
                        errors.value.CHARGE_CARD_ID.missing = utils.validateMandatoryFields(
                            FORM.value.CHARGE_CARD_ID,
                            'string'
                        )
                        errors.value.ACCOUNT_NAME.missing = utils.validateMandatoryFields(
                            FORM.value.ACCOUNT_NAME,
                            ''
                        )
                        errors.value.IBAN.missing = utils.validateMandatoryFields(
                            FORM.value.IBAN,
                            ''
                        )
                    }
                    break
                case 'BANKANDOPTIONALCHARGECARD':
                    errors.value.ACCOUNT_NAME.missing = utils.validateMandatoryFields(
                        FORM.value.ACCOUNT_NAME,
                        'string'
                    )
                    errors.value.IBAN.missing = utils.validateMandatoryFields(
                        FORM.value.IBAN,
                        'string'
                    )
                    errors.value.CHARGE_CARD_ID.missing = utils.validateMandatoryFields(
                        FORM.value.CHARGE_CARD_ID,
                        ''
                    )
                    break
            }
            // check if IBAN is in correct format
            if (FORM.value.IBAN !== '') {
                errors.value.IBAN.invalid = !IBAN.isValid(FORM.value.IBAN)
            }
        }

        const isFormHasErrors = computed(() => {
            /* check if the vat validation from BE has errors. `isVatValidationFailed` will only consider when the BE response has been recieved
            Otherwise it consider has `isVatValidationFailed` false */
            const isVatValidationFailed = isVatValidationResponseRecieved.value
                ? displayVatBEValidationError.value
                : false

            return isVatValidationFailed || utils.hasErrors(errors.value)
        })
        const summaryView = () => {
            validateForm()
            if (utils.hasEmojisOverall(FORM.value)) {
                emojis.value = true
                return
            }

            if (isFormHasErrors.value) {
                if (FORM.value.PAYMENT_METHOD === '') {
                    isPaymentMethodSelected.value = true
                    return
                }
                editable.value = true
                window.scrollTo({ top: 0, behavior: 'smooth' })
                return
            } else {
                editable.value = false
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }

        const getRequest = () => {
            const queries = router.currentRoute.value.query
            const storedLSYearsChoices = store.state.tcAndPp

            const data = {
                gender: FORM.value.GENDER ? FORM.value.GENDER?.code : null,
                first_name: FORM.value.FIRST_NAME,
                last_name: FORM.value.LAST_NAME,
                account_iban: FORM.value.IBAN,
                account_name: FORM.value.ACCOUNT_NAME,
                charge_card: FORM.value.CHARGE_CARD_ID,
                account_bic: '',
                partner_variable: FORM.value.PARTNER_VARIABLE,
                street_name: FORM.value.STREET_NAME,
                street_number: FORM.value.STREET_NUMBER,
                postal_code: FORM.value.POSTAL_CODE,
                campaign: campaign ? JSON.parse(campaign) : '',
                city: FORM.value.CITY,
                free_text: freeText,
                full_url_variable_string: URLVariableValue ? JSON.parse(URLVariableValue) : '',
                vehicle_owner_type: privateOrCompany.value,
                is_vat_mandatory: false,
                name_of_business: '',
                vat_number: '',
                email_promotion_opt_in: queries.email_promotion
                    ? JSON.parse(String(queries.email_promotion))
                    : storedLSYearsChoices.emailPromotion
                    ? JSON.parse(storedLSYearsChoices.emailPromotion)
                    : false,
                signup_url: '',
                login_url: '',
            }
            // if business is selected
            if (isCompany.value && showPrivateOrCompany.value) {
                data.name_of_business = FORM.value.COMPANY_NAME
                // if vat required is selected
                if (isVAT.value) {
                    data.is_vat_mandatory = true
                    data.vat_number =
                        FORM.value.TAX_NUMBER &&
                        utils.removeSpace(FORM.value.TAX_NUMBER).replace('-', '')
                }
            }
            // send signup and login urls when end user portal is enabled
            if (isEndUserPortalEnabled.value) {
                data.signup_url = `${window.location.origin}/portal/auth/signup`
                data.login_url = `${window.location.origin}/portal/auth/login`
            }

            return data
        }

        const displayErrors = (err: {
            response: { data: { account_iban: { error_code: string } } }
        }) => {
            // display server errors
            const errorCode = err.response?.data && err.response?.data?.account_iban?.error_code
            // display the error from FE, if we didn't receive any server error
            if (!errorCode) {
                errorMessage.value = 'IBAN wurde nicht richtig eingegeben'
            } else {
                errorMessage.value = utils.getErrorMessage(errorCode)
            }
        }

        // lock submitting client info
        const lockClient = async (clientId: number) => {
            APIService.lockClient(clientId)
                .then(async () => {
                    loading.value = false
                    localStorage.setItem(variables.LOCAL_STORAGE_ITEMS.BILLING_SUCCESS, 'true')
                    // navigate to registrations if the end user portal is enabled for the user

                    if (isEndUserPortalEnabled.value) {
                        isRedirecting.value = true
                        await commonUtils.sleep(3000)
                        router.push('portal/registrations')
                    } else {
                        router.push({ name: 'Finish', query: router.currentRoute.value?.query })
                    }
                })
                .catch((err) => {
                    loading.value = false
                    error.value = true
                    displayErrors(err)
                    throw Error('client lock failed')
                })
        }
        // billing submit
        const submit = () => {
            if (isFormHasErrors.value) {
                editable.value = true
                return
            }
            const data = getRequest()

            loading.value = true
            APIService.billingSubmit(data)
                .then(async (response) => {
                    // When response was successful we lock the object.
                    // After this, we cannot update this specific client
                    // anymore. We do this to signal to the api that we are
                    // done with editing this specific object.
                    localStorage.setItem(
                        commonVariables.LOCAL_STORAGE_ITEMS.EMAIL,
                        JSON.stringify(response?.data?.email)
                    )
                    const clientId = response.data.id
                    lockClient(clientId)
                })
                .catch((err) => {
                    loading.value = false
                    error.value = true
                    displayErrors(err)
                    throw Error('submit failed')
                })
        }

        // payement method dropdown onchange
        const paymentMethodOnChange = (event: DropdownChangeEvent) => {
            selectedPaymentMethod = event.value.code
            if (selectedPaymentMethod === 'BANK') {
                isIBAN.value = true
                //FORM.value.CHARGE_CARD_ID = ''
                isChargeCard.value = false
                isPaymentMethodSelected.value = false
            } else if (selectedPaymentMethod === 'CHARGECARD') {
                isIBAN.value = false
                isChargeCard.value = true
                isPaymentMethodSelected.value = false
                ;(FORM.value.IBAN = ''), (FORM.value.ACCOUNT_NAME = '')
            } else {
                isIBAN.value = false
                isChargeCard.value = false
            }
        }

        const resetVatValidationBE = () => {
            isVatValidationResponseRecieved.value = false
            displayVatBEValidationError.value = false
            displayVatChecked.value = false
            vatErrorMessage.value = ''
        }

        /* If BE doesn't send the response when user is in the editable or summary page, user can proceed the journey.
        But if the BE response recieved and the validation is invalid, then user should not be able to proceed */
        const vatValidation = () => {
            resetVatValidationBE()
            validateBusinessFields()
            const isVATWithDE = FORM.value.TAX_NUMBER.toLowerCase().startsWith('de')
            // only apply BE validation when the basic FE validations have passed
            if (errors.value.TAX_NUMBER.missing || errors.value.TAX_NUMBER.invalid) {
                return
            }
            /* eventhough this BE validation should be done only for DE-* VAT numbers, BE needs other type of VAT numbers also to
            update the record in db. But this check must not be visible in the page. Hence load the spinner only if the VAT number starts with DE */
            if (isVATWithDE) {
                displayVatValidationSpinner.value = true
            }
            const formData = new FormData()
            formData.append('vat_number', FORM.value.TAX_NUMBER)
            APIService.validateVatNumber(formData)
                .then(async (response) => {
                    displayVatValidationSpinner.value = false
                    isVatValidationResponseRecieved.value = true
                    // display the verified icon only when the VAT number is valid while it's a VAT number which starts with DE-* (BE sends the status as valid for the VAT numbers which doesn't starts with DE)
                    if (response.data.status === 'valid' && isVATWithDE) {
                        displayVatChecked.value = true
                    }
                })
                .catch((err) => {
                    displayVatValidationSpinner.value = false
                    isVatValidationResponseRecieved.value = true
                    const errorCode = err.response?.data?.error_code
                    // display the error and fallback the page to editable mode
                    if (errorCode) {
                        displayVatBEValidationError.value = true
                        editable.value = true
                        vatErrorMessage.value = utils.getErrorMessage(errorCode)
                    }
                    console.error('validation failed')
                })
        }

        const removeIBANspaces = () => {
            FORM.value.IBAN = utils.removeSpace(FORM.value.IBAN)
        }

        const noVatSelected = () => {
            FORM.value.TAX_NUMBER = ''
            resetVatValidationBE()
        }

        const isCompany = computed(() => {
            return privateOrCompany.value == 'business'
        })

        const isVAT = computed(() => {
            return FORM.value.VAT_MANDATORY == 'yes-vat'
        })

        const switchPrivatCompany = () => {
            resetVatValidationBE()
            FORM.value.TAX_NUMBER = ''
        }

        return {
            state: store.state,
            showDot: store.state.config.display_title_dot,
            alignment: store.state.config.item_alignment,
            partner: store.state.config.partner,
            payment: store.state.config.pages.payment,
            privateOrCompany,
            isCompany,
            isVAT,
            utils,
            FORM,
            errors,
            summaryView,
            loading,
            isRedirecting,
            error,
            errorMessage,
            editable,
            submit,
            paymentMethodOnChange,
            isIBAN,
            isChargeCard,
            selectedCity: null,
            paymentMethodArr: [
                { name: dropDownBankLabel, code: 'BANK' },
                { name: dropDownChargeCardLabel, code: 'CHARGECARD' },
            ],
            isPaymentMethodSelected,
            chargeCardId,
            showPrivateOrCompany,
            emojis,
            removeIBANspaces,
            isGenderOptionsEnabled,
            genders,
            noVatSelected,
            serverErrors,
            displayVatValidationSpinner,
            displayVatBEValidationError,
            isVatValidationResponseRecieved,
            vatValidation,
            vatErrorMessage,
            switchPrivatCompany,
            displayVatChecked,
        }
    },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/mixins';
@import '@/assets/styles/variables';
fieldset {
    border: none;
}
.p-field small {
    display: inherit;
}
.input-label {
    text-align: left;
}
.p-component {
    font-family: var(--tqs-font-family);
}
.radio-button-label {
    font-size: rem(21px);
}
.group-fields {
    display: inline-flex;
    align-items: center;
    width: 100%;
}
.pi-check-circle {
    color: $green !important;
}
</style>
