import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45a13ee2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "registration-wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "advert"
}
const _hoisted_3 = { class: "advert__wrap" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "advert__img" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 1,
  class: "advert__text"
}
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "advert__notice" }
const _hoisted_10 = { class: "body-container" }
const _hoisted_11 = { class: "registration-header grid" }
const _hoisted_12 = { class: "right-block col-12 sm:col-4 w-full" }
const _hoisted_13 = { class: "flex justify-content-end" }
const _hoisted_14 = { class: "registrations-body" }
const _hoisted_15 = {
  key: 0,
  class: "component-list-wrapper registration-blocks-wrapper"
}
const _hoisted_16 = ["id"]
const _hoisted_17 = {
  key: 0,
  class: "paginater-wrapper"
}
const _hoisted_18 = {
  key: 1,
  class: "no-data"
}
const _hoisted_19 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonPV = _resolveComponent("ButtonPV")!
  const _component_RegistrationBlock = _resolveComponent("RegistrationBlock")!
  const _component_PaginatorPV = _resolveComponent("PaginatorPV")!
  const _component_MessagePV = _resolveComponent("MessagePV")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.advertisement && _ctx.advertisement?.enabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.advertisement.type === 'image')
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.advertisement.url,
                  target: "_blank"
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("img", {
                      src: _ctx.advertisement.image
                    }, null, 8, _hoisted_6)
                  ])
                ], 8, _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.advertisement.type === 'freetext')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    innerHTML: _ctx.advertisement?.freetext
                  }, null, 8, _hoisted_8)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('registrations.advertisement')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_ButtonPV, {
              label: _ctx.$t('registrations.new_registration'),
              class: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToRegistration()))
            }, null, 8, ["label"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.events.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.events, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "mb-3",
                  id: `section-${item.sequence}`
                }, [
                  _createVNode(_component_RegistrationBlock, {
                    item: item,
                    events: _ctx.events,
                    onResubmitRegistration: _ctx.resubmitRegistration,
                    onGoToPage: _ctx.goToPage,
                    pageNumber: _ctx.pagination.page
                  }, null, 8, ["item", "events", "onResubmitRegistration", "onGoToPage", "pageNumber"])
                ], 8, _hoisted_16))
              }), 128)),
              (_ctx.pagination.total > 0 && _ctx.events?.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                    _createVNode(_component_PaginatorPV, {
                      class: "qa-pagination",
                      rows: _ctx.pagination.limit,
                      totalRecords: _ctx.pagination.total,
                      first: (_ctx.pagination.page - 1) * _ctx.pagination.limit,
                      onPage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPaginationChange($event)))
                    }, null, 8, ["rows", "totalRecords", "first"])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        (_ctx.events?.length === 0 && !_ctx.loading)
          ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
              _createVNode(_component_MessagePV, {
                severity: "info",
                closable: false
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: _ctx.$t('registrations.no_data')
                  }, null, 8, _hoisted_19)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}