<template>
    <div>
        <div
            class="form-msg-wrapper"
            :class="[isFormMsg ? 'show' : '', isError ? 'error' : 'success']"
        >
            <p>{{ formErrorContent }}</p>
        </div>
        <h2 class="title">{{ $t('signup.title') }}</h2>
        <p class="description">{{ $t('signup.description') }}</p>
        <form @submit.prevent="submitSignup()">
            <div class="field">
                <input
                    id="username"
                    type="text"
                    class="inputfield w-full"
                    v-model="SIGNUP_FORM.USERNAME"
                    :disabled="true"
                />
            </div>
            <div class="field">
                <input
                    id="password"
                    type="password"
                    class="inputfield w-full"
                    :class="[v$.PASSWORD.$dirty && v$.PASSWORD.required.$invalid ? 'error' : '']"
                    :placeholder="[
                        v$.PASSWORD.$dirty && v$.PASSWORD.required.$invalid
                            ? $t('miscellaneous.error_msgs.required')
                            : $t('miscellaneous.placeholders.password'),
                    ]"
                    v-model="SIGNUP_FORM.PASSWORD"
                    @keyup="v$.PASSWORD.$validate"
                />
                <div v-if="v$.PASSWORD.$dirty && v$.PASSWORD.required.$invalid">
                    <span class="form-error">{{
                        $t('password_reset.new_password_required_msg')
                    }}</span>
                </div>
                <div v-else>
                    <span
                        v-if="v$.PASSWORD.$dirty && v$.PASSWORD.minLength.$invalid"
                        class="form-error"
                        >{{ $t('password_reset.new_password_eight_characters_msg') }}</span
                    >
                    <span
                        v-if="
                            v$.PASSWORD.$dirty && v$.PASSWORD.containsPasswordRequirement.$invalid
                        "
                        class="form-error"
                        >{{ $t('password_reset.new_password_pattern_msg') }}</span
                    >
                </div>
            </div>
            <!-- display terms and conditions only for new users who don't have any registrations -->
            <div class="field-checkbox" v-if="storedEmailVerificationDetails.is_unknown_user">
                <CheckboxPV v-model="SIGNUP_FORM.TERMS" :binary="true" />
                <label>
                    {{ $t('signup.terms_pre') }}
                    <a
                        style="cursor: pointer"
                        @click="$router.push({ name: 'TermsAndConditions' })"
                        >{{ $t('miscellaneous.terms_and_conditions') }}</a
                    >
                    {{ $t('signup.terms_post') }}
                </label>
            </div>
            <div class="field login-btn">
                <ButtonPV
                    :label="$t('signup.button_signup')"
                    class="button-raised w-full primary"
                    type="submit"
                    :disabled="disableSignup"
                />
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue'
import router from '@/router'
import AuthenticationService from '@/end-user-portal/shared/services/AuthenticationService'
import { useI18n } from 'vue3-i18n'
import commonVariables from '@/shared/Variables'
import { required, minLength, helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import store from '@/store'
import commonUtils from '@/shared/mixins/utils'
import utils from '@/end-user-portal/shared/mixins/utils'

export default defineComponent({
    setup() {
        const { t } = useI18n()
        const isdisable = ref(false)
        const isError = ref(false)
        const isFormMsg = ref(false)
        const formErrorContent = ref('')
        const email = localStorage.getItem(commonVariables.LOCAL_STORAGE_ITEMS.EMAIL)
        const signupKey = router.currentRoute.value.query.key
        // get stored verification details.
        const storedEmailVerificationDetails = computed(() => store.state.emailVerificationDetails)

        // map them into local variable as default values
        const verificationDetails = ref({
            emailVerificationCompleted:
                storedEmailVerificationDetails.value.emailVerificationCompleted,
            emailVerified: storedEmailVerificationDetails.value.emailVerified,
            email: storedEmailVerificationDetails.value.email,
            is_unknown_user: storedEmailVerificationDetails.value.is_unknown_user,
        })
        const SIGNUP_FORM = ref({
            USERNAME: email ? JSON.parse(email) : storedEmailVerificationDetails.value.email,
            PASSWORD: '',
            TERMS: false,
        })

        const disableSignup = computed(() => {
            // if user is new, disable sign up button if emailVerified is false or terms is not checked
            if (storedEmailVerificationDetails.value.is_unknown_user) {
                return !(
                    storedEmailVerificationDetails.value.emailVerified && SIGNUP_FORM.value.TERMS
                )
            } else {
                //  disable signup button if emailVerified is false
                return !storedEmailVerificationDetails.value.emailVerified
            }
        })

        const rules = {
            PASSWORD: {
                required,
                minLength: minLength(8),
                containsPasswordRequirement: helpers.withMessage(
                    () => t('password_reset.new_password_pattern_msg'),
                    (value: string) => utils.validateFields(value, 'password')
                ),
            },
        }
        const v$ = useVuelidate(rules, SIGNUP_FORM.value)

        const submitSignup = async () => {
            if (v$.value.$invalid) {
                v$.value.$touch()
                return
            }
            // validateForm()
            // TODO: verify if subdomain is required hence we send it via request headers now
            const data = {
                password: SIGNUP_FORM.value.PASSWORD,
                subdomain: commonUtils.subdomain,
            }
            AuthenticationService.signUp(data)
                .then(() => {
                    router.push({ name: 'RegistrationsView' })
                })
                .catch((error: { response: { data: { detail: string } } }) => {
                    isError.value = true
                    isFormMsg.value = true

                    formErrorContent.value = error.response?.data?.detail
                })
        }

        // verify email when user aceess signup page from email
        const verifySignupEmail = async () => {
            const data = {
                key: String(signupKey),
            }
            await AuthenticationService.verifySignupEmail(data)
                .then(
                    (response: {
                        data: {
                            access_token: string
                            refresh_token: string
                            email_address: string
                            is_unknown_user: boolean
                        }
                    }) => {
                        SIGNUP_FORM.value.USERNAME = response.data.email_address
                        localStorage.setItem(
                            commonVariables.LOCAL_STORAGE_ITEMS.TOKEN,
                            response?.data?.access_token
                        )
                        localStorage.setItem(
                            commonVariables.LOCAL_STORAGE_ITEMS.REFRESH_TOKEN,
                            response?.data?.refresh_token
                        )
                        verificationDetails.value = {
                            emailVerificationCompleted: true,
                            emailVerified: true,
                            email: response.data.email_address,
                            is_unknown_user: response.data.is_unknown_user,
                        }
                    }
                )
                .catch(() => {
                    verificationDetails.value.emailVerificationCompleted = true
                    verificationDetails.value.emailVerified = false
                    isFormMsg.value = true
                    isError.value = true
                    formErrorContent.value = t('miscellaneous.error_msgs.email_verification')
                })
            store.commit('setEmailVerificationDetails', verificationDetails.value)
        }

        onMounted(async () => {
            // if `key` exists, and verification endpoint has not called, call `verifySignupEmail()` to verify key(accessing via email)
            if (!verificationDetails.value.emailVerificationCompleted && signupKey) {
                await verifySignupEmail()
            } else {
                // set this `emailVerified` as true when user comes via registrations flow, there is no need of email verification
                verificationDetails.value.emailVerified = true
                store.commit('setEmailVerificationDetails', verificationDetails.value)
            }
        })

        return {
            SIGNUP_FORM,
            v$,
            isFormMsg,
            formErrorContent,
            isError,
            isdisable,
            submitSignup,
            disableSignup,
            storedEmailVerificationDetails,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables';
@import '@/assets/styles/mixins';
@import '@/end-user-portal/assets/styles/login';
.field-checkbox {
    font-size: 12px;
    label {
        line-height: 1.5;
    }
    .p-checkbox {
        .p-checkbox-box {
            width: 20px !important;
            height: 20px !important;
        }
    }
}
</style>
